import styled from 'styled-components';


export const ImageWrapper = styled.div`
  display: inline-block;
  ${({maxWidth}) => maxWidth && `max-width: ${maxWidth};`}

  img {
    display: block;
    width: 100%;
  }
`;