import styled from 'styled-components';
import * as Text from '../../styles/shared/typography';
import * as colors from '../../styles/shared/colors';


export const ListContainer = styled.div`
`;

export const ListTitle = styled(Text.H6)`
  margin-bottom: 24px;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  font-weight: 700;
  color: ${({unbranded}) => unbranded ? colors.vhlGrey : colors.typeDark };
  list-style: ${({noIcon}) => noIcon ? 'inside': 'none'};
  
  &:before {
    content: '';
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background-image: url("${({bgPath}) => bgPath}");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
