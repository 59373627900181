import React from 'react';
import * as s from './styles';


const ImgWrapper = ({maxWidth, children}) => {
  return (
    <s.ImageWrapper maxWidth={maxWidth}>
      { children }
    </s.ImageWrapper>
  );
};


export default ImgWrapper;