import styled from 'styled-components';
import { respondTo } from '../shared/breakpoints';
import { Footnote } from '../shared/typography';


export const ListFootnotes = styled(Footnote)`
  display: block;
  max-width: 800px;

  ${respondTo.sm`
    margin: -30px 0 0;
  `}

  &.custom-mt{
    ${respondTo.sm`
      margin: 10px 0 0;
    `}
  }

`;

export const PaddingRight = styled.div`
  ${respondTo.sm`
  `}
`;