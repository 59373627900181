import * as React from 'react';
import { respondTo } from '../../styles/shared/breakpoints';
// Components
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import PageSection from '../../components/PageSection';
import FlexRow from '../../components/FlexRow';
import IconCard from '../../components/IconCard';
import IconList from '../../components/IconList';
import ImgWrapper from '../../components/ImgWrapper';
import * as Text from '../../styles/shared/typography';
import * as s from '../../styles/pages/vhl';
import Reference from '../../components/Reference';
import styled from 'styled-components';
// Content
import vhlCopy from '../../copy/vhl';
import { CalloutBanner } from '../../components';

const pageMeta = {
  title: 'What Is von Hippel-Lindau (VHL) Disease?',
  keywords: 'what is von hippel-lindau disease, what is vhl disease',
  description: 'Von Hippel-Lindau disease is a rare, genetic, systemic syndrome that can cause recurrent tumor development in the kidneys, CNS, and pancreas. Learn more about VHL here.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/what-is-von-hippel-lindau/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/what-is-von-hippel-lindau/","@type":"MedicalWebPage","name":"What Is VHL Disease?","description":"VHL disease is a rare, genetic, systemic syndrome that can cause recurrent tumor development in the kidneys, CNS, and pancreas"}`    
  ]
}

const Spacer16 = styled.div`
  height: 16px;
`;

const Spacer28 = styled.div`
  height: 28px;
`;

const SpacerMobile15 = styled.div`
  height: 15px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const SpacerMobile30 = styled.div`
  height: 30px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BulletedList = styled(IconList)`
  ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    li {
      font-weight: 400;
      height: 21px;
      margin-bottom: 0px;
      &:before {
        width: 6px;
        height: 21px;
      }
    }
  }

  h6{
    margin-bottom: 25px;
    margin-top: 10px;

    ${respondTo.sm`
      margin-bottom: 45px;
      margin-top: 0px;
    `}
  }

`;

const VHLTITLE = styled(Text.H3)`
  color: black;
  font-weight: 500;
`;

const VhlPage = () => {

  const calloutBanner = {
    title: "Learn more about these attributes of WELIREG:",
    continualInvertedButtons: true,
    links: [
      {
        label: "Efficacy",
        url: "/von-hippel-lindau/efficacy",
      },
      {
        label: "Dosing",
        url: "/von-hippel-lindau/dosing",
      },
      {
        label: "Mechanism of Action",
        url: "/von-hippel-lindau/mechanism-of-action/",
      }
    ]
  };

  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/what-is-von-hippel-lindau/" />
      </PageSeo>
      <PageTitle className="black" unbranded>
        { vhlCopy.pageTitle }
      </PageTitle>


      <PageSection btmShort>
        <s.PaddingRight>
        <VHLTITLE
          unbranded
          dangerouslySetInnerHTML={{__html: vhlCopy.section1Title}}
        />
        <Text.BodySection
          unbranded
          dangerouslySetInnerHTML={{__html: vhlCopy.section1Body}}
        />
        </s.PaddingRight>
      </PageSection>

      <PageSection flat>
        <FlexRow gap="20px">

          {vhlCopy.iconCards.map((card, i) => (
            <IconCard
              key={i}
              unbranded
              title={ card.title }
              icon={ card.icon }
              copy={ card.listItems }
              alt={ card.alt }
            />
          ))}

        </FlexRow>
      </PageSection>
      <SpacerMobile30 />
      <PageSection flat>
        <FlexRow gap="10px" alignCenter noItemWrapper>
          <BulletedList
            unbranded
            title={ vhlCopy.otherList.title }
            listItems={ vhlCopy.otherList.listItems }
          />
          <ImgWrapper maxWidth={'347px'}>
            <img
              src={ vhlCopy.labeledPersonImg }
              alt={ vhlCopy.labeledPersonAlt }
            />
          </ImgWrapper>
        </FlexRow>
        <SpacerMobile15 />
        <s.ListFootnotes className='custom-mt'>
          { vhlCopy.footnote }
        </s.ListFootnotes>
      </PageSection>
    
      <Spacer16 />
      <PageSection flat>
        <Reference data={vhlCopy.references} />
      </PageSection>
      <Spacer28 />

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default VhlPage;


