import React from 'react';
import PropTypes from 'prop-types';
import * as s from './styles';


const IconList = ({title, listItems, unbranded, className}) => {
  return (
    <s.ListContainer className={className}>
      <s.ListTitle
        unbranded={unbranded}
        dangerouslySetInnerHTML={{__html: title}}
      />
      <s.List>
        {listItems.map((item, i) => (
          <s.ListItem
            key={i}
            bgPath={item.icon}
            unbranded={unbranded}
          >
            { item.copy }
          </s.ListItem>
        ))}
      </s.List>
    </s.ListContainer>
  );
};

IconList.propTypes = {
  unbranded: PropTypes.bool,
  title: PropTypes.string,
  listItems: PropTypes.array,
};


export default IconList;