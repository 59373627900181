import PersonImg from '../assets/images/img-person-unbranded.png';
import KidneyIcon from '../assets/images/illust-kidney-unbranded.svg';
import BrainIcon from '../assets/images/illust-brain-unbranded.svg';
import PancreasIcon from '../assets/images/illust-pancreas-unbranded.svg';
import Bullet from '../assets/images/bullet.svg';

const vhlCopy = {
  pageTitle: 'What Is VHL Disease?',
  section1Title: 'VHL disease is a rare, genetic, systemic syndrome that can cause recurrent tumor development in the kidneys, CNS, and pancreas<sup>1,2</sup>',
  section1Body: `
    <p><strong>VHL disease</strong> is a multi-organ, autosomal dominant condition characterized by abnormal cell growth and caused by genetic aberrations of the tumor suppressor gene VHL.<sup>1</sup></p>
    <p>Tumors that develop are often benign but may cause symptoms, depending on location, as the tumor grows. Some tumors, however, may become cancerous, such as those found in the kidneys and pancreas.<sup>3</sup></p>
  `,

  iconCards: [
    {
      title: 'RCC: kidneys',
      icon: KidneyIcon,
      listItems: [
        'As many as 70% of people with VHL disease develop RCC by 60 years of age<sup>4,5</sup>',
        'RCC begins to appear at a median age of 31 years<sup>6</sup>',
      ],
      alt: " ",
    },
    {
      title: 'CNS hemangioblastomas: brain and spinal cord',
      icon: BrainIcon,
      listItems: [
        'These are the most common tumors in VHL disease, affecting up to 80% of patients<sup>1</sup>',
        'The average age of presentation is 33 years<sup>1</sup>',
      ],
      alt: " ",
    },
    {
      title: 'pNET: pancreas',
      icon: PancreasIcon,
      listItems: [
        'Approximately 9% to 17% of patients with VHL disease have pancreatic neuroendocrine tumors<sup>7</sup>',
        'The mean age of onset is approximately 35 years<sup>7</sup>',
      ],
      alt: " ",
    },
  ],

  otherList: {
    title: 'Patients with VHL disease commonly develop other tumors, including<sup>1</sup>',
    listItems: [
      {
        icon: Bullet,
        copy: 'Hemangioblastomas: retina',
      },
      {
        icon: Bullet,
        copy: 'Endolymphatic sac tumors: inner ear',
      },
      {
        icon: Bullet,
        copy: 'Pheochromocytomas: adrenal glands',
      },
    ],
  },

  labeledPersonImg: PersonImg,
  labeledPersonAlt: 'Possible Sites of von Hippel-Lindau (VHL) Disease–associated Tumors',

    footnote: ' VHL = von Hippel-Lindau; CNS = central nervous system; RCC = renal cell carcinoma; pNET = pancreatic neuroendocrine tumors.',

  bottomBtn: {
    label: 'View the MOA',
    to: '/von-hippel-lindau/mechanism-of-action',
  },

  references: [
    'Varshney N, Kebede AA, Owusu-Dapaah H, et al. A review of von Hippel-Lindau syndrome. <em>J Kidney Cancer VHL.</em> 2017;4(3):20–29.',
    'National Organization for Rare Disorders. Von Hippel-Lindau disease. https://rarediseases.org/rare-diseases/von-hippel-lindau-disease/. Accessed November 13, 2023.',
    'VHL Alliance website. What is VHL? https://www.vhl.org/care-treatment/what-is-vhl/. Accessed January 26, 2024.',
    'Chittiboina P, Lonser RR. Von Hippel–Lindau disease. <em>Handb Clin Neurol.</em> 2015;132:139–156.',
    'Maher ER, Yates JR, Harries R, et al. Clinical features and natural history of von Hippel-Lindau disease. <em>Q J Med.</em> 1990;77(283):1151–1163.',
    'Feletti A, Anglani M, Scarpa B, et al. Von Hippel-Lindau disease: an evaluation of natural history and functional disability. <em>Neuro Oncol.</em> 2016;18(7):1011–1020.',
    'Ganeshan D, Menias CO, Pickhardt PJ, et al. Tumors in von Hippel–Lindau syndrome: from head to toe—comprehensive state-of-the-art review. <em>RadioGraphics.</em> 2018;38(3):849–866. ',
  ],

  buttonBlade: {
    title: 'Learn more about these attributes of WELIREG:',
    buttonLabelLeft: 'Efficacy',
    buttonHrefLeft: '/von-hippel-lindau/efficacy',
    buttonLabelMid: 'Dosing',
    buttonHrefMid: '/von-hippel-lindau/dosing',
    buttonLabelRight: 'Mechanism of Action',
    buttonHrefRight: '/von-hippel-lindau/mechanism-of-action',
  },
};

export default vhlCopy;
